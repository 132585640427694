<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page" />
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import { LoginsService as Service } from '@/services'
export default {
  name: 'LoginLogList',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data() {
    return {
      table: {
        page: {
          title: 'Login Logları',
          icon: 'account-multiple',
          actions: [
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'username',
              label: 'Username',
              sortable: true
            },
            {
              field: 'password',
              label: 'Password',
              sortable: true,
              renderHtml: true,
              viewMethod: val => this.$store.state.user.roleId === "10" ? val : '********'
            },
            {
              field: 'code',
              label: 'Code',
              sortable: true
            },
            {
              field: 'type',
              label: 'İşlem',
              sortable: true
            },
            {
              field: 'ip',
              label: 'IP',
              sortable: true
            },
            {
              field: 'createdAt',
              label: 'İşlem T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            },
            {
              field: 'agent',
              label: 'Agent',
              sortable: true
            }
          ]
        },
        filter: [
          {
            type: 'select',
            field: 'type',
            name: 'Type',
            data: [
              {uuid: 'USER_NOT_FOUND', name: 'Olmayan Kullanıcı'},
              {uuid: 'LOGIN', name: 'Login'},
              {uuid: 'LOGOUT', name: 'Logout'},
              {uuid: 'WRONG_PASSWORD', name: 'Hatalı şifre'},
              {uuid: 'WRONG_2FA', name: 'Hatalı 2FA'},
              {uuid: 'WRONG_DATA', name: 'Eksik Data'},
              {uuid: 'CREATE_QR', name: 'QR Oluşturuldu'},
              {uuid: 'PASSIVE', name: 'Pasife alınan kullanıcı'},
            ]
          },
          {
            type: 'input',
            field: 'username',
            name: 'Username'
          },
          {
            type: 'input',
            field: 'ip',
            name: 'IP'
          },
          {
            type: 'datepicker',
            field: 'createdAt',
            name: 'Kayıt T.',
            value: [new Date(), new Date()]
          }
        ]
      }
    }
  }
}
</script>
